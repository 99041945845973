import "./styles.scss";

import * as React from "react";

import HomeVideo from "../HomeVideo/HomeVideo";
import { NavHashLink as Link } from "react-router-hash-link";
import { Link as ScrollLink } from "react-scroll";
import connect from "react-redux/es/connect/connect";
import heroBenefit1 from "./../../../../img/svg/benefit-1.svg";
import heroBenefit2 from "./../../../../img/svg/benefit-2.svg";
import heroBenefit3 from "./../../../../img/svg/benefit-3.svg";
import operatingCompanyIds from "../../../../constants/operatingCompanyIds";
import signupTypes from "../../../../constants/signupTypes";
import { withRouter } from "react-router-dom";

class HomeCommonInfo extends React.PureComponent<*> {
  componentDidMount() {
    if (window && window.location && window.location.hash === "#video") {
      const offset = document.getElementById("video").offsetTop;

      window.scrollTo({
        top: offset,
        left: 0,
        behavior: "smooth"
      });
    }
  }

  renderCTAButton = buttonText => {
    const { selectedOperatingCompany, location } = this.props;
    const pathName = location ? location.pathname : "";

    const isTexas = !!(
      selectedOperatingCompany &&
      selectedOperatingCompany.id === operatingCompanyIds.Texas
    );

    const isSepoB =
      selectedOperatingCompany &&
      selectedOperatingCompany.abbreviation === "EAL-B";

    if (
      pathName !== "/plans" &&
      pathName !== "/signup" &&
      pathName !== "/join-waitlist" &&
      selectedOperatingCompany &&
      selectedOperatingCompany.name !== "Other" &&
      selectedOperatingCompany.hasCapacity
    ) {
      if (isSepoB) {
        return (
          <Link className="btn" to="get-started">
            Get Started
          </Link>
        );
      } else {
        return (
          <Link className="btn" to="plans">
            {isTexas
              ? "Join Waitlist"
              : selectedOperatingCompany.isEnrollmentEnabled
              ? "Join Now"
              : "Reserve Now"}
          </Link>
        );
      }
    } else if (
      pathName !== "/plans" &&
      pathName !== "/signup" &&
      pathName !== "/join-waitlist" &&
      selectedOperatingCompany &&
      selectedOperatingCompany.name !== "Other" &&
      !selectedOperatingCompany.hasCapacity
    ) {
      if (isSepoB) {
        return (
          <Link className="btn" to="get-started">
            Get Started
          </Link>
        );
      } else {
        return (
          <Link className="btn" to="join-waitlist">
            Join Waitlist
          </Link>
        );
      }
    }
  };

  renderSubTitle(visibility) {
    const { selectedOperatingCompany: { id, signupType } = {} } = this.props;
    const { selectedOperatingCompany } = this.props;

    if (
      selectedOperatingCompany &&
      selectedOperatingCompany.abbreviation === "EAL-B"
    ) {
      return (
        <span>Entergy Arkansas Solar Energy Purchase Option- Option B </span>
      );
    } else if (!id) {
      return null;
    } else if (visibility.noFarms) {
      return (
        <span>Introducing sharable solar farms - join the waitlist today.</span>
      );
    } else if (signupType === signupTypes.Signup) {
      return (
        <span>
          Introducing sharable solar farms you can subscribe to today.
        </span>
      );
    } else if (signupType === signupTypes.Reserve) {
      return (
        <span>Introducing sharable solar farms - reserve your spot today!</span>
      );
    } else if (signupType === signupTypes.JoinWaitlist) {
      return (
        <span>Shareable solar farms are on their way to your community.</span>
      );
    } else {
      return (
        <span>
          Help us build a solar community through shareable solar farms.
        </span>
      );
    }
  }

  render() {
    const {
      selectedOperatingCompany,
      selectedOperatingCompany: { visibility } = { visibility: {} }
    } = this.props;

    const isOther = !!(
      selectedOperatingCompany &&
      selectedOperatingCompany.id === operatingCompanyIds.Other
    );
    const isTexas = !!(
      selectedOperatingCompany &&
      selectedOperatingCompany.id === operatingCompanyIds.Texas
    );
    const isSepoB =
      selectedOperatingCompany &&
      selectedOperatingCompany.abbreviation === "EAL-B";

    return (
      <div>
        <div className="hero-block home-cta-section">
          <div className="centered-content">
            <div className="content-panel gs-grid">
              {visibility.noFarms && !isSepoB ? (
                <h1>
                  We’re on a mission to bring solar to Entergy{" "}
                  {selectedOperatingCompany.stateFullName}.
                </h1>
              ) : !isSepoB ? (
                <h1>We’re on a mission to help you run with solar power.</h1>
              ) : (
                <h1>Welcome to Solarity Arkansas’ SEPO Option B!</h1>
              )}
              <p>{this.renderSubTitle(visibility)}</p>
              <div className="row align-items-center">
                <div className="col">
                  {isOther ? (
                    <ScrollLink to="video" smooth={true} className="btn">
                      Watch Video
                    </ScrollLink>
                  ) : (
                    this.renderCTAButton()
                  )}
                </div>
                <div className="col">
                  {!isSepoB && (
                    <Link to="/about-us" className="learn-more-link">
                      Learn more about community solar
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {visibility.noFarms && (
          <div className="centered-content">
            <div className="hero-block home-no-farms-card gs-grid">
              <div className="row">
                <div className="col home-no-farms-card-image">
                  <img
                    src="https://d1oeu1h51wn2cw.cloudfront.net/desktop/Texas_Illustration.jpg"
                    alt="Solar Farm Illustration"
                  />
                </div>
                <div className="col home-no-farms-card-description">
                  <h1>
                    Harnessing{" "}
                    {isTexas
                      ? "Texas-sized"
                      : selectedOperatingCompany.stateFullName}{" "}
                    sunshine into solar power
                  </h1>
                  <p>
                    At Entergy, we’re investing in sustainable energy solutions
                    that will power the future of{" "}
                    {selectedOperatingCompany.stateFullName}. In order to pave
                    the the the way for solar power, we need your help.
                  </p>
                  <p>
                    Show your support for community solar by joining our
                    waitlist. Sign up and help us shine a light on sustainable
                    energy in {selectedOperatingCompany.stateFullName}.
                  </p>
                  {this.renderCTAButton("Show Your Support")}
                </div>
              </div>
            </div>
          </div>
        )}

        {!isSepoB && (
          <div className="hero-block-benefits">
            <div className="centered-content">
              <div className="hero-block-benefits__item">
                <div>
                  <div className="img">
                    <img src={heroBenefit1} alt="" />
                  </div>
                  <strong>Solar power for all</strong>Forget the traditional
                  barriers to clean energy, everyone can join the solar power
                  movement.
                </div>
              </div>

              <div className="hero-block-benefits__item">
                <div>
                  <div className="img">
                    <img src={heroBenefit2} alt="" />
                  </div>
                  <strong>The switch is simple</strong>
                  Subscribing to a solar community offering is quick, easy and
                  just a click away, - no new account, no separate bills, no
                  home installation.
                </div>
              </div>

              <div className="hero-block-benefits__item">
                <div>
                  <div className="img">
                    <img src={heroBenefit3} alt="" />
                  </div>
                  <strong>A little bit goes a long way</strong>
                  Invest in a clean, renewable future for generations to come
                  with a few extra dollars on your electricity bill.
                </div>
              </div>
            </div>
          </div>
        )}

        {isSepoB && (
          <div className="centered-content sepob-block">
            <h2 className="content-heading" id="how-it-works">
              What is SEPO Option B?
            </h2>
            <p>
              Solar Energy Purchase Option (“SEPO”) – Option B is a
              subscription-based solar energy offering that will allow you to
              off-set your energy usage (kWh) under the current standard rate
              schedule at the full 1:1 retail rate, much like net-metering
              customers. SEPO Option B gives you the benefits of Entergy
              Arkansas’ existing solar facilities, without the increased risks
              of private solar. There are many benefits to your participation in
              SEPO B such as no upfront investment and no on-premise solar
              facility to operate and maintain; subscriptions do not convey the
              associated renewable energy credits.
            </p>
            <br />
            <p>
              Customers with eligible accounts may sign up for a subscription of
              a minimum of one kilowatt (kW) with additional increments of one
              kW to serve up to 100 percent of the qualifying account’s total
              usage (based on the preceding 12-month billing history). There is
              no limit to the number of qualifying accounts that can take
              service under SEPO Option B as long as there is available capacity
              from an Entergy Arkansas designated solar resource(s).
            </p>
            <br />
            <p>
              Interested in joining?{" "}
              <Link to="/get-started" className="learn-more-link">
                Sign up today
              </Link>
            </p>
          </div>
        )}

        {!isSepoB && (
          <div className="centered-content">
            <h2 className="content-heading" id="how-it-works">
              How community solar works
            </h2>
            <div className="how-it-works-steps">
              <div className="how-it-works-steps__item">
                <strong>Step 1</strong>
                You pick the solar plan that’s best for your lifestyle and
                budget.
              </div>
              <div className="how-it-works-steps__item">
                <strong>Step 2</strong>
                Our solar farms produce renewable electricity, which gets added
                to the grid.
              </div>
              <div className="how-it-works-steps__item">
                <strong>Step 3</strong>
                Based on solar output, you’ll see your Solarity plan change
                month-to-month.
              </div>
            </div>
            <HomeVideo />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  selectedOperatingCompany:
    state.operatingCompaniesReducer.selectedOperatingCompany,
  operatingCompanies: state.operatingCompaniesReducer.operatingCompanies
});

export default connect(mapStateToProps)(withRouter(HomeCommonInfo));
